.faq-enter {
    max-height: 0;
    overflow: hidden;
    transition: max-height 300ms ease-in-out;
  }
  .faq-enter-active {
    max-height: 200px; /* Adjust based on content */
  }
  .faq-exit {
    max-height: 200px; /* Adjust based on content */
    overflow: hidden;
  }
  .faq-exit-active {
    max-height: 0;
    transition: max-height 300ms ease-in-out;
  }
  