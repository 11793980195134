/* aboutUs.css */
.content-enter {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height 300ms ease-in, opacity 300ms ease-in;
  }
  .content-enter-active {
    max-height: 500px; /* Adjust as needed */
    opacity: 1;
  }
  .content-exit {
    max-height: 500px; /* Adjust as needed */
    opacity: 1;
    overflow: hidden;
    transition: max-height 300ms ease-out, opacity 300ms ease-out;
  }
  .content-exit-active {
    max-height: 0;
    opacity: 0;
  }
  